
import Utils from '@/assets/utils/utils';
import Item from '@/database/models/item';
import Machine from '@/database/models/machine';
import Booking from '@/database/models/booking';
import Invoice from '@/database/models/invoice';
import BuildMemberData from '@/assets/utils/buildMemberData';

export default
{

  //Renvoi les crédits
  async getCredit( uid_member )
  {
    return new Promise(async(resolve)=>
    {
      Booking.getTabAllMemoryKey( uid_member, ( list_booking )=>
      {
          Item.getTabAllMemoryKey( uid_member,( list_item )=>
          {
              //Build Item
              let rep = BuildMemberData.GetCredit( list_booking, list_item );
              resolve( rep.credit );
          });
      });
    });
  },

  //Renvoi tous les crédits
  async getAllCredits( uid_member )
  {
    return new Promise(async(resolve)=>
    {
      Booking.getTabAllMemoryKey( uid_member, ( list_booking )=>
      {
          Item.getTabAllMemoryKey( uid_member,( list_item )=>
          {
              //Build Item
              let rep = BuildMemberData.GetCredit( list_booking, list_item );
              resolve( rep );
          });
      });
    });
  },


  //Renvoi les crédits avec filtre type
  async getCreditFiltre( uid_member, filtre )
  {
    return new Promise(async(resolve)=>
    {
      Booking.getTabAllMemoryKey( uid_member, ( list_booking )=>
      {
          Item.getTabAllMemoryKey( uid_member,( list_item )=>
          {
              //Build Item
              let rep = BuildMemberData.GetCredit( list_booking, list_item );
              let data = rep.credit[filtre];
              if( data == null )
                data = {
                  credit:0,
                  used:0
                };

              resolve( data );
          });
      });
    });
  },


  //Renvoi la consomation total d'un membre
  async getMemberConsumption( uid_member )
  {
    return new Promise(async (resolve)=>
    {
      Item.getTabAllMemoryKey( uid_member, ( list_item )=>
      {
        let data = BuildMemberData.BuildConsumption( list_item );
        resolve( data );
      });
    });
  },


  async getMemberCreance( uid_member )
  {
    return new Promise(async (resolve)=>
    {
      Invoice.getTabAllMemoryKey( uid_member, ( list_invoice )=>
      {
        let data = BuildMemberData.BuildCreance( list_invoice );
        resolve( data );
      });
    });
  },


  async calcCreance( uid_member )
  {
      //get list
      let list = await Invoice.AwaitGetTabAllMemoryKey( uid_member );

      //Order by date
      list.sort(function(a, b)
      {
          let time_a = (new Date(a.createAt)).getTime();
          let time_b = (new Date(b.createAt)).getTime();
          return time_b - time_a;
      });

      let rep = {
          dif:0,
          recu:0,
          date:null,
      }
      let buf_fac = null;

      for( let i=0; i<list.length; i++)
      {
          let buffer = list[i];
          switch(buffer.type)
          {
              case 'av':
              case 'fa':
                  if( buffer.pay_dif != 0 )
                      rep.dif += buffer.pay_dif

                  if( buffer.pay_prele != 0 ) {
                    let facturePrele = false
                    if (buffer.mollie_id) {
                      if ( (buffer.mollie_id).search("tr_") == 0 ) {
                        facturePrele = true
                      }
                    }

                    if (!facturePrele) rep.dif += buffer.pay_prele;
                  }

                  // if( buffer.pay_check != 0 )
                  //     rep.dif += buffer.pay_check
                  
                  // || buffer.pay_check != 0
                  if(buffer.pay_dif != 0 || buffer.pay_prele != 0)
                      buf_fac = new Date( buffer.createAt );
              break;
              case 're':
                  if(rep.date==null)
                      rep.date = new Date( buffer.createAt );
                  
                  rep.recu += Number(buffer.pay_money) + Number(buffer.pay_cb) + Number(buffer.pay_check) + Number(buffer.pay_prele)
              break;
          }

          if(rep.date==null)
              rep.date = buf_fac;
      }

      return rep;
  },



  //Renvoi les crédits avec filtre type
  async getItemParse( uid_member )
  {
    return new Promise(async(resolve)=>
    {
          Item.getTabAllMemoryKey( uid_member,( list_item )=>
          {
              let data = BuildMemberData.getItemParse( list_item );
              resolve( data );
          });
    });
  },


  async itemCreditUse( uid_member )
  {
    return new Promise(async(resolve)=>
    {
      Booking.getTabAllMemoryKey( uid_member, ( list_booking )=>
      {
          Item.getTabAllMemoryKey( uid_member,( list_item )=>
          {
              //Build Item
              let data = BuildMemberData.fget( list_booking, list_item );
              resolve( data );
          });
      });
    });
  }
}